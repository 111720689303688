<template>
  <div class="modal" id="modal-circulo-carregando" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modalCirculoCarregandoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered d-flex justify-content-center">
      <div class="spinner-border circulo-animado" role="status">
        <span class="visually-hidden">Carregando...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap"


export default {
  name: "ModalCirculoCarregando",
  setup(props, context) {
    let circuloCarregando = null
    const mostraCirculoCarregando = () => {
      circuloCarregando = new Modal(document.getElementById("modal-circulo-carregando"))
      circuloCarregando.show()
    }
    const escondeCirculoCarregando = () => {
      if (circuloCarregando) {
        circuloCarregando.hide()
        circuloCarregando = null
      }
    }

    context.expose({ mostraCirculoCarregando, escondeCirculoCarregando })
    return { mostraCirculoCarregando, escondeCirculoCarregando }
  },
}
</script>

<style scoped>
.circulo-animado {
  width: 5rem;
  height: 5rem;
  border-width: 0.5rem;
  color: var(--cor-primaria);
}
</style>