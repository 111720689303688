<template>
  <div>
    <router-view />
  </div>
  <ModalCirculoCarregando ref="circulo" />
</template>

<script>
import { inject, ref, onMounted, onUnmounted } from "vue"
import ModalCirculoCarregando from "@/components/shared/modais/ModalCirculoCarregando.vue"

export default {
  components: {
    ModalCirculoCarregando,
  },
  setup() {
    // Configura ativação e desativação de círculo carregando via eventos loading-begin e loading-end
    const circulo = ref(null)
    const eventBus = inject("eventBus")

    onMounted(() => {
      eventBus.on("loading-begin", () => circulo.value.mostraCirculoCarregando())
      eventBus.on("loading-end", () => circulo.value.escondeCirculoCarregando())
    })
    onUnmounted(() => {
      eventBus.off("loading-begin")
      eventBus.off("loading-end")
    })

    return { circulo }
  },
}
</script>

<style src="@/app.css"></style>
