import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"

import { createApp as createVueApp } from "vue"
import router from "./router"
import store from "./store"
import eventBus from "./plugins/eventBus"
import VueGtag from "vue-gtag"
import App from "./App.vue"


export const createApp = () => {
    return createVueApp(App).use(store).use(router).use(eventBus).use(VueGtag, {
        config: { id: process.env.VUE_APP_ANALYTICS_ID },
        router,
    })
}