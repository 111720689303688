import { createRouter, createWebHistory } from "vue-router";

const rotas = [
  {
    path: "/",
    name: "cadastro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cadastro" */ "@/views/CadastroView.vue"),
  },
  {
    path: "/formulario",
    name: "formulario-cadastro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "formulario-cadastro" */ "@/views/FormularioCadastroView.vue"
      ),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "cadastro" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: rotas,
});

export default router;
